import React from "react";
import { Link } from "rebass";
import Tippy from "@tippy.js/react";
import styled from "styled-components";
import PropTypes from "prop-types";
import FontAwesomeIcon from "react-fontawesome";

const IconLink = styled(Link)`
  transition: color 0.5s;
  color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.social_link};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.colors.onHover_social_link};
    border-bottom: 5px solid
      ${props => props.theme.colors.onHover_underline_social_link};
  }
`;

const SocialLink = ({ fontAwesomeIcon, name, url, color }) => (
  <Tippy content={name} placement="bottom" trigger="mouseenter">
    <IconLink
      href={url}
      target="_blank"
      color={color}
      rel="noreferrer"
      aria-label={name}
    >
      <FontAwesomeIcon name={fontAwesomeIcon} />
    </IconLink>
  </Tippy>
);

SocialLink.propTypes = {
  fontAwesomeIcon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default SocialLink;
